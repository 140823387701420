import * as React from "react";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import loginService from "../services/AuthService";
import config from "../config";
import authService from "../services/AuthService";
import i18n from "../i18n";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import Swal from "sweetalert2";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      formErrors: {},
      loading: false,
    };
  }

  async componentDidMount() {
    const token = localStorage.getItem(config.tokenKey);
    if (token && token !== "" && token !== "undefined") {
      let isAuthentic = await authService.verifyToken();
      if (!isAuthentic.error) {
        this.props.navigate("/dashboard");
      }
    } else {
      if (token == "" || token == "undefined") {
        localStorage.clear();
      }
    }
  }

  async isFormValid() {
    let error = await this.validateFormField();
    if (Object?.keys(error)?.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async validateFormField(field) {
    let error = {...this.state.formErrors};

    if (field == null || field === "username") {
      if (!this.state.username || this.state.username === "") {
        error.username = i18n.t("this_field_is_required");
      } else {
		delete error?.[field]
	}
    }

    if (field == null || field === "password") {
      if (!this.state.password || this.state.password === "") {
        error.password = i18n.t("this_field_is_required");
      } else {
		delete error?.[field]
	}
    }

    this.setState({
      formErrors: error,
    });

    return error;
  }

  async login() {
    if (!(await this.isFormValid())) {
      return;
    }

    this.setState({ loading: true });
    let payload = {
      username: this.state.username,
      password: this.state.password,
    };
    let response = await loginService.login(payload);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: response.error,
      });
      this.setState({ loading: false });
      return;
    }
    localStorage.setItem(config.tokenKey, response.token);
    localStorage.setItem(config.roleKey, response.role);
    this.props.navigate("/dashboard");
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            {i18n.t("login")} | {config.constants.applicationName}
          </title>
        </Helmet>
        <Container component="main" maxWidth="xs" style={styles?.container}>
          <CssBaseline />
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <Container
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "0",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {i18n.t("login")}
              </Typography>
            </Container>
            <Grid component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label={i18n.t("username")}
                name="username"
                autoComplete="username"
                autoFocus
                onChange={async (e) => {
                  await this.setState({
                    username: e.target.value,
                  });
                  await this.validateFormField("username");
                }}
                error={this.state.formErrors.username ? true : false}
                helperText={this.state.formErrors.username}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={i18n.t("password")}
                type="password"
                id="password"
                autoComplete="current-password"
                error={this.state.formErrors.password ? true : false}
                helperText={this.state.formErrors.password}
                onChange={async (e) => {
                  await this.setState({
                    password: e.target.value,
                  });
                  await this.validateFormField("password");
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.login();
                  }
                }}
              />
              <Button
                disabled={this.state.loading}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={async () => {
                  await this.login();
                }}
              >
                {this.state?.loading ? i18n.t("logging_in") : i18n.t("login")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

const styles = {
  container: {
    display: "flex",
    height: "100vh",
    alignItems: "center",
  },
};
export default Login;
