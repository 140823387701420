import axios from 'axios';

import config from '../config';
import getQueryStringFromObject from '../utils/queryFormatter';

const server = axios.create({
    baseURL: config.baseUrl
});

export const getUsers = async (payload) => {
    try {
        let url = getQueryStringFromObject(payload);
        const response = await server.get(`/users?${url}`, {
            headers: {
              token: localStorage.getItem('token')
            }
          });
          return response.data;
          
    } catch (error) {
        return error.response.data;
    }
};

export const getUser = async (id) => {
    try {
        const response = await server.get(`/users/${id}`,
        {headers:{token:localStorage?.getItem('token')}});
        return response.data;
    } catch (error) {
        return error;
    }
};

export const addUser = async (payload) => {
    try {
        const response = await server.post(`/users`,payload,{
            headers:{token:localStorage?.getItem('token')}});
        return response.data;
    } catch (error) {
        return error;
    }
}
export const updateUser = async (payload,id) => {
    try {
        const response = await server.patch(`/users/${id}`,payload,{
            headers:{token:localStorage?.getItem('token')}});
        return response.data;
    } catch (error) {
        return error;
    }
}

export const getUserTransactions = async (payload,id) => {
    try {
        let url = getQueryStringFromObject(payload);
        const response = await server.get(`/users/${id}/transactions?${url}`,{
            headers:{token:localStorage?.getItem('token')}});
        return response.data;
    } catch (error) {
        return error;
    }
}

export const getUserTerminalStatus = async (id, terminalId = null) => {
    try {
        let url = terminalId ? `?terminalId=${terminalId}` : '';
        const response = await server.get(`/users/${id}/terminal/status${url}`,{
            headers:{token:localStorage?.getItem('token')}
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const pairPosTerminal = async (id, terminalId = null) => {
    try {
        let body = {};
        if(terminalId) {
            body.terminalId = terminalId;
        }
        const response = await server.post(`/users/${id}/terminal/pair`, body, {
            headers:{token:localStorage?.getItem('token')}
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const unPairPosterminal = async (id, terminalId = null) => {
    try {
        let body = {};
        if (terminalId) {
            body.terminalId = terminalId;
        }
        const response = await server.post(`/users/${id}/terminal/unpair`, body, {
            headers:{token:localStorage?.getItem('token')}
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const getPairStatus = async (id) => {
    try {
        const response = await server.get(`/users/${id}/terminal/pairStatus`,
        {headers:{token:localStorage?.getItem('token')}});
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUnpairStatus = async (id) => {
    try {
        const response = await server.get(`/users/${id}/terminal/unpairStatus`,
        {headers:{token:localStorage?.getItem('token')}});
        return response.data;
    } catch (error) {
        return error;
    }
};