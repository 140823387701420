import { Button } from "@mui/material";
import React from "react";
import i18n from "../../i18n";
import { uploadFile } from "../../services/AssetsService";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
class UploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.uploadType = props?.uploadType;
    this.fileType=props?.fileType;
    this.multipleFileUpload=props?.multiple;
    this.Swal = require("sweetalert2");
    this.state = {};
    this.handleFileSelection = props?.handleFileSelection;
    this.label = props?.label;
  }
  async uploadAssets(payload,files) {
    this.Swal.showLoading();
    let response = await uploadFile(payload);
    if (response?.response?.status === 500) {
      this.Swal.close();
      this.Swal.fire({
        icon: "error",
        text: response.error,
      });
      return;
    }
    if (response?.response?.status !== 500) {
      this.Swal.close();
      this.Swal.fire({
        toast: true,
        icon: "success",
        position: "top-end",
        text: i18n.t("file_uploaded_successfully"),
        showConfirmButton: false,
        timer: 3000,
      });
      if(this.uploadType === 'certificate')  {
        this.handleFileSelection('add','certificate',{name:files?.[0]?.name,_id:response?.assets?.[0]})
        return;
      }
      this.handleFileSelection('add','assets',response?.assets);
    }
  }
  async handleSelection(selectedFiles) {
    let payload = new FormData();
    let imageFiles=[...selectedFiles];
    imageFiles?.map((file,idx) =>{
      if(this.uploadType){
        payload.append("certificate", file)
      }else{
        payload.append("file" + idx, file)
      }
    }
    );
    this.uploadAssets(payload,selectedFiles);
  }
  render() {
    return (
      <>
        <Button
          variant="outlined"
          component="label"
          sx={{width:'100%',height:'100%'}}
          startIcon={<CloudUploadIcon />}
        >
         {this?.label}
          <input
            type="file"
            multiple={this.multipleFileUpload ? true : false}
            accept={this.fileType ? `${this.fileType}/*` : ''}
            onChange={(e) => {
              this.handleSelection(e.target.files);
            }}
            hidden
          />
        </Button>
      </>
    );
  }
}
export default UploadFile;
