import i18next from 'i18next';
import enTranslations from './en.json';
import frTranslations from './fr.json';

i18next.init({
//   lng: 'en', // if you're using a language detector, do not define the lng option
//   debug: true,
  resources: {
    en: {
      translation: enTranslations
    },
    fr: {
        translation: frTranslations
    }
  },
  fallbackLng: 'en'
});

const i18n = i18next;

export default i18n;