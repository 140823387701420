import { Helmet } from "react-helmet-async";
import React, { Component } from "react";

// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TableHead,
  Grid,
  Pagination,
  TextField,
  InputAdornment,
  Tooltip,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";

// components
import Iconify from "../components/iconify";

// icons
import EditIcon from '@mui/icons-material/Edit';
import PinDropIcon from '@mui/icons-material/PinDrop';

// sections

// mock
import Swal from "sweetalert2";
import { listBars } from "../services/BarService";
import { Search } from "@mui/icons-material";
import i18n from "../i18n";
import config from "../config";

// ----------------------------------------------------------------------

class BarsPage extends Component {
  constructor(props) {
    super(props);
    this.role = localStorage.getItem(config.roleKey);
    this.tableLimitOptions = config?.tableLimitOptions
    this.state = {
      rows: [],
      page: 1,
      limit: 10,
      skip: 0,
      pageCount: 0,
      search: "",
      isNotFound: false,
      open: null,
      rowData: null,
      orderBy: { sortkey: "name", sortValue: true }
    };
  }

  componentDidMount() {
    this.getBarsList();
  }

  getBarsList = async (show_loading = true) => {

    if (show_loading) {
      Swal.showLoading();
    }

    const { limit, skip, search, orderBy } = this.state;
    let payload = {
      limit,
      skip,
      sortkey: orderBy?.sortkey,
      sortorder: orderBy?.sortValue ? "ASC" : "DESC",
    };
    if (search !== '') {
      payload['searchvalue'] = search
    }
    let response = await listBars(payload);
    if (response.error) {
      if (show_loading) {
        Swal.close();
      }
      alert(response.error)
      return;
    }
    if (response) {
      let page_count = Math.ceil(response.count / limit);

      this.setState({
        rows: response.bars,
        pageCount: page_count,
      });

      if (show_loading) {
        Swal.close();
      }
    }
  };

  handleOpenMenu = (event, rowData) => {
    this.setState({
      open: event.currentTarget,
      rowData,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      open: null,
      rowData: null,
    });
  };

  handleLimitChange = () => {

  }
  render() {
    const { t } = this.props;
    const {
      rows,
      page,
      limit,
      pageCount,
      search,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>{i18n.t("bars")} | Kestuboua</title>
        </Helmet>

        <Container style={{ maxWidth: '100%' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" className="m-0" gutterBottom>
              {i18n.t("bars")}
            </Typography>
            {(this.role === config.roles?.SUPERADMIN?.key || this.role === config.roles?.ADMIN?.key) && <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => {
                this.props.navigate("/bars/create");
              }}
            >
              {i18n.t("add_bar")}
            </Button>}
          </Stack>
          <Card style={{ padding: "24px", borderRadius: 'unset' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  label={i18n.t("search")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "100%" }}
                  variant="outlined"
                  value={search}
                  onChange={async (e) => {
                    await this.setState({ page: 1, skip: 0, search: e.target.value });
                    this.getBarsList(false);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableSortLabel
                            direction={
                              this.state.orderBy?.sortValue ? "asc" : "desc"
                            }
                            active={true}
                            onClick={async (e) => {
                              await this.setState({
                                orderBy: {
                                  sortkey: "name",
                                  sortValue: !this.state.orderBy?.sortValue,
                                },
                              });
                              this.getBarsList();
                            }}
                          >
                            {i18n.t("name")}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>{i18n.t("description")}</TableCell>
                        <TableCell>{i18n.t("status")}</TableCell>
                        <TableCell>{i18n.t("actions")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows?.map((row) => (
                        <TableRow
                          key={row.username}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row?.name}
                          </TableCell>
                          <TableCell component="th" scope="row">{row?.description}</TableCell>
                          <TableCell>
                            <span
                              style={{
                                backgroundColor: (row.status === "active" ? "green" : row.status === "inactive" ? "orange" : "red"),
                                padding: 5,
                                color: 'white',
                                borderRadius: 5,
                                fontSize: 12,
                                fontWeight: 'bold'
                              }}
                            >
                              {i18n.t(row.status)}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span>
                              <Tooltip title={i18n.t("edit")} placement="top-start">
                                <IconButton onClick={() => {
                                  this.props.navigate(`/bars/${row?._id}`);
                                }}>
                                  <EditIcon
                                    color="primary"
                                  />
                                </IconButton>
                              </Tooltip>
                            </span>
                            {
                              row?.location?.coordinates?.[0] && row?.location?.coordinates?.[1] ? (
                                <span style={{ paddingLeft: '24px' }}>
                                  <Tooltip title={i18n.t("geoLocation")} placement="top-start">
                                    <IconButton onClick={() => {
                                      window.open(`https://maps.google.com/maps?q=${row?.location?.coordinates?.[1]},${row?.location?.coordinates?.[0]}`, '_blank');
                                    }}>
                                      <PinDropIcon
                                        color="primary"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </span>
                              ) : <></>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container spacing={2}  style={{ width: '100%' , display:'flex' , justifyContent:"space-between" , alignItems:'center'}}>
              <Grid></Grid>
              <Grid item>
                <Pagination variant="outlined" shape="rounded"
                  count={pageCount}
                  page={page}
                  onChange={async (e, value) => {
                    await this.setState({
                      page: value,
                      skip: (value - 1) * limit
                    });
                    this.getBarsList();
                  }}
                  style={{ margin: '10px', marginTop: '24px' }}
                />
              </Grid>

              <Grid item style={{ width: "10%" }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                  <Select
                    value={this.state.limit}
                    label="Limit"
                    size="small"
                    onChange={async (e) => {
                      await this.setState({
                        ...this.state,
                        limit: e?.target?.value
                      })
                      this.getBarsList();
                    }}
                  >
                    {this.tableLimitOptions?.map((limit) => {
                      return (
                        <MenuItem value={limit?.value}>{limit?.label}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </>
    );
  }
}

export default BarsPage;
