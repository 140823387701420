import {
  Autocomplete,
  Card,
  Container,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import i18next from "i18next";
import moment from "moment";
import React from "react";
import i18n from "../../i18n";
import Swal from "sweetalert2";
import authService from "../../services/AuthService";
import {
  getDailyStatistics,
  getMonthStatistics,
  getWeekStatistics,
  getDateRangeStatistics,
} from "../../services/StatisticsService";
import ConsumptionsTable from "./consumptionsTable";
import OnlineTransactionsTable from "./onlineTransactionsTable";
import Widgets from "./widgets";
import { listBars } from "../../services/BarService";
import config from "../../config";
// get current locale
const locale = i18next.language;
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.Swal = require("sweetalert2");
    this.state = {
      consumptions: [],
      orderDetails: [],
      paymentTypeDetails: null,
      payrexxStatusDetails: null,
      onlineTransactions: [],
      statisticsMode: "daily",
      selectedDate: new Date(),
      selectedStartDate: new Date(),
      selectedEndDate: new Date(),
      loading: false,
      barOptions:[],
      barFilterValue:{name:"",value:""}
    };
    this.role = localStorage.getItem('role')
  }

  async componentDidMount() {
    let isAuthentic = await authService.verifyToken();
    if (isAuthentic.error) {
      Swal.fire({
        icon: "error",
        title: isAuthentic.error_code
          ? i18n.t(isAuthentic.error_code)
          : isAuthentic.error,
      });
      return;
    }
    await this.getBarList();
  }

  handleStatisticsModeChange = async (event, newStatisticsMode) => {
    if (newStatisticsMode !== null) {
      await this.setState({
        statisticsMode: newStatisticsMode,
      });
      await this.getStatisticsData();
    }
  };

  async getStatisticsData() {
    this.setState({
      loading: true,
    });

    let statistics;

    if (this.state.statisticsMode === "daily") {
      if(this.state.barFilterValue?.value ==='all_bars'){
        statistics = await getDailyStatistics(this.state.selectedDate);
      }else{
        statistics = await getDailyStatistics(this.state.selectedDate,this.state.barFilterValue?.value);
      }
    } else if (this.state.statisticsMode === "last_7_days") {
      if(this.state.barFilterValue?.value ==='all_bars'){
        statistics = await getWeekStatistics();
      }else{
      statistics = await getWeekStatistics(this.state.barFilterValue?.value);
      }
    } else if (this.state.statisticsMode === "last_30_days") {
      if(this.state.barFilterValue?.value ==='all_bars'){
        statistics = await getMonthStatistics();
      }else{
      statistics = await getMonthStatistics(this.state.barFilterValue?.value);
      }
    } else if (this.state.statisticsMode === "date_range") {
      if(this.state.barFilterValue?.value ==='all_bars'){
        statistics = await getDateRangeStatistics( this.state.selectedStartDate,
          this.state.selectedEndDate,
          );
      }else{
      statistics = await getDateRangeStatistics(
        this.state.selectedStartDate,
        this.state.selectedEndDate,
        this.state.barFilterValue?.value
      );
      }
    }

    let consumptions = statistics?.consumptions;
    if (consumptions) {
      consumptions.forEach((consumption) => {
        consumption.id = consumption.name + " - " + consumption.price;
        consumption.price = consumption?.price?.toFixed(2);
        consumption.total = consumption?.total?.toFixed(2);
      });
    }

    let onlineTransactions = statistics?.onlineDetails;
    if (onlineTransactions) {
      onlineTransactions.forEach((onlineTransaction) => {
        // id should be last 6 characters of _id and capitalised
        onlineTransaction.id = onlineTransaction._id
          .substring(onlineTransaction._id.length - 6)
          .toUpperCase();
        onlineTransaction.created_at = moment(
          onlineTransaction.updatedAt
        ).format(i18n.t("OPTIONS.date_time_format"));
        onlineTransaction.total = onlineTransaction?.grandTotal?.toFixed(2);
        onlineTransaction.payrexxStatus=i18n.t(onlineTransaction?.payrexxStatus);
        onlineTransaction.paymentStatus=i18n.t(onlineTransaction?.paymentStatus)
      });
    }
    if(statistics?.error){
      this.Swal.fire({
        toast: true,
        icon: "error",
        text: i18n.t(statistics?.error_code),
        position: "center",
        showConfirmButton: false,
        timer: 4000,
      });
    }
    let tempPaymentTypeDetails=statistics?.paymentTypeDetails?.filter(payments=>payments?.paymentType !== 'cash_difference')
    this.setState({
      consumptions: statistics?.consumptions,
      orderDetails: statistics?.orderDetails,
      paymentTypeDetails: tempPaymentTypeDetails,
      payrexxStatusDetails: statistics?.payrexxStatusDetails,
      onlineTransactions: statistics?.onlineDetails,
      loading: false,
    });
  }
  async getBarList() {
    let payload = {
      minimal: true,
    };
    let response = await listBars(payload);
    let tempOptions=[{name:"All Bars",value:"all_bars"}]
    if (response) {
      response?.bars?.map((el) => {
        tempOptions.push({ name: el?.name, value: el?._id });
        return null      
      });
      if([config?.roles?.CASHIER?.key,config?.roles?.SERVER?.key].includes(this.role)){
        let tempData=tempOptions?.filter(option=>option?.value!=='all_bars')
        await this.setState({
          barOptions: [...tempData],
          barFilterValue:tempData?.[0]
        } ,() => {
          this.getStatisticsData()
        });
      }else{
        await this.setState({
          barOptions: [...tempOptions],
        });
        this.getStatisticsData();
      }
    }
    if (response.error) {
      alert(response.error);
      return;
    }
  }


  render() {
    return (
      <Container maxWidth='xxl'>
        <Grid container style={{ flexDirection: "column"}}>
          <Grid item className="d-flex" direction="row" justifyContent="center">
            <Grid item xs={12} md={11}>
              <Autocomplete
                disablePortal
                disableClearable={[config?.roles?.CASHIER?.key,config?.roles?.SERVER?.key].includes(this.role)?true:false}
                id="type"
                options={
                  !this.state.barOptions
                    ? [{ label: "Loading...", value: 0 }]
                    : this.state.barOptions
                }
                getOptionLabel={(option) => option.name ? `${option.name}` : ""}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Bar"
                  />
                )}
                onChange={async(option, value) => {
                  await this.setState({
                    barFilterValue:{name: value?.name, value: value?.value}
                  });
                  this.getStatisticsData()
                }}
                value={this.state.barFilterValue || ''}
              />
            </Grid>
          </Grid>
        
        </Grid>
        <Grid container className="mt-3 d-flex" spacing={2} direction="row"  > 
          <Grid item xs={12} md={6} className="d-flex" direction="row" justifyContent="flex-end">
          <ToggleButtonGroup
              value={this.state.statisticsMode}
              exclusive
              onChange={this.handleStatisticsModeChange}
              aria-label="text alignment"
              style={{
                marginBottom: 10,
                backgroundColor: "white",
              }}
            >
              <ToggleButton value="daily" aria-label="daily">
                {i18n.t("daily")}
              </ToggleButton>
              <ToggleButton value="last_7_days" aria-label="last 7 days">
                {i18n.t("last_7_days")}
              </ToggleButton>
              <ToggleButton value="last_30_days" aria-label="last 30 days">
                {i18n.t("last_30_days")}
              </ToggleButton>
              <ToggleButton value="date_range" aria-label="date range">
                {i18n.t("date_range")}
              </ToggleButton>
            </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} md={6}>
          {this.state.statisticsMode === "daily" ? (
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={locale}
              >
                <DatePicker
                  label={i18n.t("date")}
                  value={this.state.selectedDate}
                  onChange={async (newValue) => {
                    await this.setState({
                      selectedDate: newValue,
                    });
                    moment(newValue, 'DD//MM/YYYY',true).isValid() && this.getStatisticsData();
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat={i18n.t("OPTIONS.date_format")}
                />
              </LocalizationProvider>
          ) : null}

          {this.state.statisticsMode === "date_range" ? (
            <>
              <span style={{ marginRight: 5 }}>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale={locale}
                >
                  <DatePicker
                    label={i18n.t("start_date")}
                    value={this.state.selectedStartDate}
                    onChange={async (newValue) => {
                      let startDate = moment(newValue?._d).format("YYYY-MM-DD");
                      let endDate = moment(
                        this.state.selectedEndDate?._d
                      ).format("YYYY-MM-DD");
                      if (moment(newValue, 'DD//MM/YYYY',true).isValid() && endDate && startDate > endDate) {
                        this.Swal.fire({
                          toast: true,
                          icon: "warning",
                          text: i18n.t(
                            "start_date_cannot_be_greater_than_end_date"
                          ),
                          position: "center",
                          showConfirmButton: false,
                          timer: 4000,
                        });
                        this.setState({
                          selectedStartDate: endDate,
                        });
                      } else {
                        await this.setState({
                          selectedStartDate: newValue,
                        });
                        moment(newValue, 'DD//MM/YYYY',true).isValid() && this.getStatisticsData();
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat={i18n.t("OPTIONS.date_format")}
                  />
                </LocalizationProvider>
              </span>
              <span style={{ marginLeft: 5 }}>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale={locale}
                >
                  <DatePicker
                    label={i18n.t("end_date")}
                    value={this.state.selectedEndDate}
                    onChange={async (newValue) => {
                      let startDate = moment(
                        this.state?.selectedStartDate?._d
                      ).format("YYYY-MM-DD");
                      let endDate = moment(newValue?._d).format("YYYY-MM-DD");
                      if (startDate > endDate) {
                        this.Swal.fire({
                          toast: true,
                          icon: "warning",
                          text: i18n.t(
                            "end_date_should_be_greater_than_start_date"
                          ),
                          position: "center",
                          showConfirmButton: false,
                          timer: 4000,
                        });
                        this.setState({
                          selectedStartDate: new Date(),
                        });
                      } else {
                        await this.setState({
                          selectedEndDate: newValue,
                        });
                        moment(newValue, 'DD//MM/YYYY',true).isValid() && this.getStatisticsData();
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat={i18n.t("OPTIONS.date_format")}
                  />
                </LocalizationProvider>
              </span>
              </>
          ) : null}

          </Grid>
        </Grid>
        {this.state.loading ? (
          <Card style={{ padding: 10, marginTop: 10, borderRadius: "unset" }}>
            {i18n.t("loading")}
          </Card>
        ) : null}

        {!this.state.loading ? (
          <>
            <Widgets
              // paymentTypeDetails={this.state.paymentTypeDetails}
              paymentTypeDetails={this.state.paymentTypeDetails}
              payrexxStatusDetails={this.state.payrexxStatusDetails}
            />

            <div style={{ marginTop: 10 }}>
              <ConsumptionsTable consumptions={this.state.consumptions} />
            </div>

            <div style={{ marginTop: 10 }}>
              <OnlineTransactionsTable
                onlineTransactions={this.state.onlineTransactions}
              />
            </div>
          </>
        ) : null}
      </Container>
    );
  }
}

export default Dashboard;
