import {
  Autocomplete,
  Card,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import i18next from "i18next";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import authService from "../../services/AuthService";
import {
  getMonthlyReport,
  getMonthlyDaywiseReport,
} from "../../services/ReportsService";
import Type1Report from "./type1Report";
import Type2Report from "./type2Report";
import i18n from "../../i18n";
import { listBars } from "../../services/BarService";

// get current locale
const locale = i18next.language;

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      consumptions: [],
      orderDetails: [],
      paymentTypeDetails: null,
      paymentTypeDetails_new: null,
      payrexxStatusDetails: null,
      onlineTransactions: [],
      reportMode: "monthly",
      selectedDate: new Date(),
      selectedStartDate: new Date(),
      selectedEndDate: new Date(),
      loading: false,
      content: "<p>Hello world!</p>",
      reportData: [],
      monthlyDayWiseReport: [],
      barOptions: [],
      barFilterValue: { name: "", value: "" },
    };
  }

  async componentDidMount() {
    let isAuthentic = await authService.verifyToken();
    if (isAuthentic.error) {
      Swal.fire({
        icon: "error",
        title: isAuthentic.error_code
          ? i18n.t(isAuthentic.error_code)
          : isAuthentic.error,
      });
      return;
    }

    await this.getReportsData();
  }

  handleReportModeChange = async (event, newReportMode) => {
    if (newReportMode !== null) {
      await this.setState({
        reportData: [],
        monthlyDayWiseReport: [],
        reportMode: newReportMode,
      });
      if (newReportMode === "monthly_daywise") {
        await this.getBarList();
      } else {
        await this.getReportsData();
      }
    }
  };
  async getBarList() {
    let payload = {
      minimal: true,
    };
    let response = await listBars(payload);
    if (response) {
      let tempOptions = response?.bars?.map((el) => {
        return { name: el?.name, value: el?._id };
      });
      await this.setState({
        barOptions: [...tempOptions],
        /**setting the first bar as default option selection */
        barFilterValue: {
          name: tempOptions?.[0]?.name,
          value: tempOptions?.[0]?.value,
        },
      });
    }
    if (response.error) {
      alert(response.error);
      return;
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.barFilterValue?.value !== this.state.barFilterValue?.value) {
      this.getReportsData();
    }
  }

  async getReportsData() {
    this.setState({
      loading: true,
    });
    let reportData = [];
    let monthlyDayWiseReport = [];
    let report;
    if (this.state.reportMode === "monthly") {
      report = await getMonthlyReport(
        moment(this.state.selectedDate).format("YYYY-MM")
      );
      report = report?.data ? report.data : [];
      report = report.filter((statistic) => statistic.details.length);
      reportData = report;
    } else if (this.state.reportMode === "monthly_daywise") {
      report = await getMonthlyDaywiseReport(
        moment(this.state.selectedDate).format("YYYY-MM"),
        this.state.barFilterValue?.value
      );
      report = report?.data ? report.data : [];
      monthlyDayWiseReport = report;
    }

    this.setState({
      reportData,
      monthlyDayWiseReport,
      loading: false,
    });
  }

  render() {
    return (
      <div>
        <Grid container style={{ flexDirection: "column" }}>
          <Grid item className="d-flex" direction="row" alignItems={"center"}>
            <Grid item xs={12} md={12}>
              <ToggleButtonGroup
                value={this.state.reportMode}
                exclusive
                onChange={this.handleReportModeChange}
                aria-label="text alignment"
                style={{
                  marginBottom: 10,
                  backgroundColor: "white",
                }}
              >
                <ToggleButton value="monthly" aria-label="monthly">
                  {i18n.t("monthly")}
                </ToggleButton>
                <ToggleButton
                  value="monthly_daywise"
                  aria-label="monthly_daywise"
                >
                  {i18n.t("monthly_daywise")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid container alignItems='center' className="d-flex" direction="row"  >
            <Grid item xs={12} md={2} >
              {this.state.reportMode === "monthly" ||
              this.state.reportMode == "monthly_daywise" ? (
                <Grid item style={{ margin: "10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale={locale}
                  >
                    <DatePicker
                      label={i18n.t("month")}
                      value={this.state.selectedDate}
                      onChange={async (newValue) => {
                        await this.setState({
                          selectedDate: new Date(newValue?._d),
                        });
                        this.getReportsData();
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      inputFormat={i18n.t("OPTIONS.month_format")}
                      views={["year", "month"]}
                      openTo="month"
                    />
                  </LocalizationProvider>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12} md={10} style={{ paddingLeft: "10px" }}>
              {this.state.reportMode === "monthly_daywise" && (
                <Autocomplete
                  disablePortal
                  id="type"
                  options={
                    !this.state.barOptions
                      ? [{ label: "Loading...", value: 0 }]
                      : this.state.barOptions
                  }
                  getOptionLabel={(option) =>
                    option?.name ? option?.name : ""
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Select Bar" />
                  )}
                  onChange={async (option, value) => {
                    await this.setState({
                      barFilterValue: {
                        name: value?.name,
                        value: value?.value,
                      },
                    });
                    this.getReportsData();
                  }}
                  value={this.state.barFilterValue || ""}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        {this.state.loading ? (
          <Card style={{ padding: 10, marginTop: 10 }}>
            {i18n.t("loading")}
          </Card>
        ) : null}

        {!this.state.loading && this.state.reportMode == "monthly" ? (
          <>
            {this.state.reportData.map((report, index) => {
              return (
                <Type1Report
                  key={index}
                  report={report}
                  reportDate={this.state.selectedDate}
                  reportMode={this.state.reportMode}
                />
              );
            })}
          </>
        ) : null}

        {!this.state.loading && this.state.reportMode == "monthly_daywise" ? (
          <>
            <Type2Report
              report={this.state.monthlyDayWiseReport}
              reportDate={this.state.selectedDate}
              reportMode={this.state.reportMode}
              bar={this.state.barFilterValue}
              refreshData={() => this.getReportsData()}
            />
          </>
        ) : null}
      </div>
    );
  }
}

export default Reports;
