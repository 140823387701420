import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';

import Reports from '../components/reports/reports';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  return (
    <>
      <Helmet>
        <title>Kestuboua</title>
      </Helmet>
      <Container maxWidth="xxl">
        <Reports />
      </Container>
    </>
  );
}
