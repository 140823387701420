import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';

import Dashboard from '../components/dashboard/dashboard';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  return (
    <>
      <Helmet>
        <title>Kestuboua</title>
      </Helmet>
      <Container maxWidth="xxl">
        <Dashboard />
      </Container>
    </>
  );
}
