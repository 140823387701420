import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import config from '../../config';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

function FilterDefaultNavConfig(role, data) {
  let allowed_sidebar = ['dashboard'];
  if (role === config.roles.SUPERADMIN.key) {
    allowed_sidebar = ['dashboard','clients', 'bars', 'users', 'reports'];
  } else if (role === config.roles.ADMIN.key) {
    allowed_sidebar = ['dashboard', 'bars', 'users', 'reports'];
  } else if (role === config.roles.BAROWNER.key) {
    allowed_sidebar = ['dashboard', 'bars', 'users', 'reports'];
  } else if (role === config.roles.SERVER.key) {
    allowed_sidebar = ['dashboard'];
  }
  
  let filtered_data = data.filter((item) => {
    return allowed_sidebar.includes(item.title);
  });

  return filtered_data;
}

export default function NavSection({ data = [], ...other }) {
  const role = localStorage.getItem(config.roleKey);
  
  data = FilterDefaultNavConfig(role, data);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />
      {info && info}
    </StyledNavItem>
  );
}
