import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Modal,
  TextField,
} from "@mui/material";
import { putMonthlyDaywiseReportDayData } from "../../services/ReportsService";
import moment from "moment";
import TinyMCEEditor from "../editor/TinyMCEEditor";
import { fCurrency } from "../../utils/formatNumber";
import config from "../../config";
import i18n from "../../i18n";

function CustomToolbar() {
  return (
    <GridToolbarContainer style={{ justifyContent: "space-between" }}>
      <GridToolbarQuickFilter style={{ padding: 10 }} />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
};

class Type2Report extends React.Component {
  constructor(props) {
    super(props);
    this.paymentTypes = config?.paymentTypes;
    this.columns = [
      {
        field: "date",
        headerName: i18n.t("date"),
        flex: 1,
        valueFormatter: (params) => {
          let formatted_value = moment(params.value).format(
            i18n.t("OPTIONS.date_format")
          );
          if (formatted_value === "Invalid date") {
            return params.value;
          } else {
            return formatted_value;
          }
        },
      },
      {
        field: "cash",
        headerName: i18n.t("cash"),
        flex: 1,
        type: "number",
        valueGetter: (params) => params.row.data?.cash,
        valueFormatter: (params) => {
          return fCurrency(
            params.value,
            i18n.t("OPTIONS.i18n_number_format"),
            config.currencyCode,
            false
          );
        },
      },
      {
        field: "card",
        headerName: i18n.t("card"),
        flex: 1,
        type: "number",
        valueGetter: (params) => params.row.data?.card?.toFixed(2),
        valueFormatter: (params) => {
          return fCurrency(
            params.value,
            i18n.t("OPTIONS.i18n_number_format"),
            config.currencyCode,
            false
          );
        },
      },
      {
        field: "online",
        headerName: i18n.t("online"),
        flex: 1,
        type: "number",
        valueGetter: (params) => params.row.data?.online?.toFixed(2),
        valueFormatter: (params) => {
          return fCurrency(
            params.value,
            i18n.t("OPTIONS.i18n_number_format"),
            config.currencyCode,
            false
          );
        },
      },
    ];

    this.rows = [];
    this.bar = {};

    this.state = {
      pageSize: 50,
      modalOpen: false,
      selectedRow: null,
      cashValue: 0,
      cardValue: 0,
      content: "",
    };
  }

  componentDidMount() {
    this.createContent();
  }

  createContent() {
    let reportDate = this.props.reportDate;
    let reportMode = this.props.reportMode;
    let report = this.props.report;

    if (reportMode === "monthly" || reportMode === "monthly_daywise") {
      reportDate = moment(reportDate).format(
        i18n.t("OPTIONS.month_format_text")
      );
    } else if (reportMode === "yearly") {
      reportDate = moment(reportDate).format(i18n.t("OPTIONS.year_format"));
    } else {
      reportDate = moment(reportDate).format(i18n.t("OPTIONS.date_format"));
    }

    let aggregate = {
      cash: 0,
      card: 0,
      online: 0,
      total: 0,
    };

    // TODO - Remove the hard coded values
    let content = `
            <h3 style="text-align: center">${this.bar?.name} - ${i18n.t(
              "monthly_report"
            )}</h3>

            <h5 style="text-align: center">${i18n.t("daily_turnover")}</h5>

            <table align="right" cellpadding="10" cellspacing="10" style="margin-top: 5px; margin-bottom: 20px; border: 1px solid white">
                <tr>
                    <td style="border: 1px solid white">${i18n.t(
                      "report_generated_on"
                    )}</td>
                    <td style="border: 1px solid white" align="right">${moment().format(
                      i18n.t("OPTIONS.date_time_format")
                    )}</td>
                </tr>
                <tr>
                    <td style="border: 1px solid white">${i18n.t(
                      "report_type"
                    )}</td>
                    <td style="border: 1px solid white" align="right">${i18n.t(
                      reportMode
                    )}</td>
                </tr>
                <tr>
                    <td style="border: 1px solid white">${i18n.t(
                      "report_for"
                    )}</td>
                    <td style="border: 1px solid white" align="right">${reportDate}</td>
                </tr>
            </table>
        `;

    content += `
            <table width="100%" border="1.0">
                <tr>
                    <th>${i18n.t("date")}</th>
                    <th>${i18n.t("cash")}</th>
                    <th>${i18n.t("card")}</th>
                    <th>${i18n.t("online")}</th>
                    <th>${i18n.t("total")}</th>
                </tr>
        `;

    report.forEach((row) => {
      if (!row.data) {
        row.data = {};
      }

      row.data.cash = row.data.cash ? row.data.cash : 0;
      row.data.card = row.data.card ? row.data.card : 0;
      row.data.online = row.data.online ? row.data.online : 0;
      row.data.total = row.data.cash + row.data.card + row.data.online;

      aggregate.cash += row.data.cash;
      aggregate.card += row.data.card;
      aggregate.online += row.data.online;
      aggregate.total += row.data.total;

      content += `
                <tr>
                    <th align="left">${moment(row.date).format(
                      i18n.t("OPTIONS.date_format")
                    )}</th>
                    <td align="right">${fCurrency(
                      row.data.cash,
                      i18n.t("OPTIONS.i18n_number_format"),
                      config.currencyCode,
                      false
                    )}</td>
                    <td align="right">${fCurrency(
                      row.data.card,
                      i18n.t("OPTIONS.i18n_number_format"),
                      config.currencyCode,
                      false
                    )}</td>
                    <td align="right">${fCurrency(
                      row.data.online,
                      i18n.t("OPTIONS.i18n_number_format"),
                      config.currencyCode,
                      false
                    )}</td>
                    <td align="right">${fCurrency(
                      row.data.total,
                      i18n.t("OPTIONS.i18n_number_format"),
                      config.currencyCode,
                      false
                    )}</td>
                </tr>
            `;
    });

    content += `
                <tr>
                    <th>${i18n.t("total")}</th>
                    <th align="right">${fCurrency(
                      aggregate.cash,
                      i18n.t("OPTIONS.i18n_number_format"),
                      config.currencyCode
                    )}</th>
                    <th align="right">${fCurrency(
                      aggregate.card,
                      i18n.t("OPTIONS.i18n_number_format"),
                      config.currencyCode
                    )}</th>
                    <th align="right">${fCurrency(
                      aggregate.online,
                      i18n.t("OPTIONS.i18n_number_format"),
                      config.currencyCode
                    )}</th>
                    <th align="right">${fCurrency(
                      aggregate.total,
                      i18n.t("OPTIONS.i18n_number_format"),
                      config.currencyCode
                    )}</th>
                </tr>
            </table>
        `;

    this.setState({ content: content });
  }

  render() {
    this.rows = this.props?.report || [];
    this.bar = this.props?.bar || {};
    // clone rows
    this.rows = JSON.parse(JSON.stringify(this.rows));
    if (this.rows?.length > 0) {
      let totalCash = 0;
      let totalCard = 0;
      let totalOnline = 0;
      let tempRowData = this?.rows?.map((row) => {
        totalCash += row.data?.cash || 0;
        totalCard += row.data?.card || 0;
        totalOnline += row.data?.online || 0;
        if (!row?.data?.cash) row.data['cash'] = 0;
        if (!row?.data?.card) row.data['card'] = 0;
        if (!row?.data?.online) row.data['online'] = 0;
        return row;
      });

      let totalRow = {
        date: i18n.t("total"),
        day: Math.random() * 100 * 0.1 * 1000,
        data: {
          cash: totalCash,
          card: totalCard,
          online: totalOnline,
        },
      };

      tempRowData.push(totalRow);
      this.rows = tempRowData;
    }
    return (
      <>
        <Card>
          <CardHeader title={i18n.t("monthly_daywise")} />
          <CardContent>
            <DataGrid
              autoHeight
              rows={this.rows}
              getRowId={(row) => row?.bar?._id || row?.day}
              columns={this.columns}
              pageSize={this.state.pageSize}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              onPageSizeChange={(newPageSize) =>
                this.setState({ pageSize: newPageSize })
              }
              components={{ Toolbar: CustomToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              onRowClick={(params) => {
                if (params.id == i18n.t("total")) {
                  return;
                }
              }}
              onCellClick={(params)=>{
                if(params.field === this.paymentTypes?.[0]?.value && params?.row?.date !== 'Total'){
                   this.setState({
                  modalOpen: {cash:true,card:false},
                  selectedRow: params.row,
                  cashValue: params.row.data?.cash,
                });
              }
              if(params.field === this.paymentTypes?.[1]?.value && params?.row?.date !== 'Total'){
                this.setState({
               modalOpen: {cash:false,card:true},
               selectedRow: params.row,
               cardValue: params.row.data?.card,
             });
              }
            }}

              disableSelectionOnClick
            />
          </CardContent>
        </Card>

        <div>
          <TinyMCEEditor
            content={this.state.content}
            readonly={true}
            autoHeight={true}
          />
        </div>

        <Modal
          open={this.state.modalOpen.cash || this.state.modalOpen.card }
          onClose={() =>
            this.setState({
              modalOpen: false,
              selectedRow: null,
              cashValue: 0,
              cardValue: 0,
            })
          }
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card sx={style}>
            {/* Sshow input for cash & card and a submit button */}
            <CardContent
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Grid
                container
                spacing={2}
                style={{ width: "100%", alignContent: "center" }}
              >
                <Grid item xs={12}>
                  <p style={{ textAlign: "center" }}>
                    {moment(this.state.selectedRow?.date).format(
                      i18n.t("OPTIONS.date_format")
                    )}
                  </p>
                </Grid>
                {this.state.modalOpen.cash && <Grid item xs={12}>
                  {/* Input to edit cash value */}
                  <TextField
                    fullWidth
                    label={i18n.t("cash")}
                    value={this.state.cashValue}
                    onChange={(e) => {
                      let enteredValue = e.target.value;
                      this.setState({ cashValue: enteredValue });
                    }}
                  />
                </Grid>}
                {this.state.modalOpen.card && <Grid item xs={12}>
                  {/* Input to edit card value */}
                  <TextField
                    fullWidth
                    label={i18n.t("card")}
                    value={this.state.cardValue}
                    onChange={(e) => {
                      let enteredValue = e.target.value;
                      this.setState({ cardValue: enteredValue });
                    }}
                  />
                </Grid>}
                <Grid item xs={12}>
                  {/* Submit material ui button */}
                  <Button
                    disabled={
                      isNaN(this.state.cashValue) || isNaN(this.state.cardValue)
                    }
                    fullWidth
                    variant="contained"
                    onClick={async () => {
                      let payload = {
                        date: this.state.selectedRow.date,
                        data: this.state.cashValue ? {
                          cash: Number(this.state.cashValue)
                        }:
                        {card: Number(this.state.cardValue)}
                      };
                      await putMonthlyDaywiseReportDayData(payload, this.bar?.value);
                      this.setState({
                        modalOpen: false,
                        selectedRow: null,
                        cashValue: 0,
                        cardValue: 0,
                      });
                      this.props.refreshData();
                    }}
                  >
                    {i18n.t("submit")}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Modal>
      </>
    );
  }
}

export default Type2Report;
