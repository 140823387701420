import axios from 'axios';
import moment from 'moment';

import config from '../config';

const server = axios.create({
    baseURL: config.baseUrl
});

export const getDailyStatistics = async (date=null,barId=null) => {
    try {
        let url = `/statistics/day`;
        if(barId){
            url = `/statistics/bar/${barId}/day`;
        }
        if (date) {
            date = moment(date?._d).format('YYYY-MM-DD');
            url += `?date=${date}`;
        }
        const response = await server.get(url, {
            headers: { token: localStorage?.getItem('token') }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const getDateRangeStatistics = async (startDate, endDate,barId) => {
    try {
    let url = `/statistics/daterange`;
    if(barId && barId!=='all_bars'){
        url = `/statistics/bar/${barId}/daterange`;
    }
        if (startDate && endDate) {
            startDate = moment(startDate?._d).format('YYYY-MM-DD');
            endDate = moment(endDate?._d).format('YYYY-MM-DD');
            url += `?startDate=${startDate}&endDate=${endDate}`;
        }
        const response = await server.get(url, {
            headers: { token: localStorage?.getItem('token') }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const getWeekStatistics = async (barId=null) => {
    try {
        let url = `/statistics/week`;
        if(barId && barId!=='all_bars'){
            url = `/statistics/bar/${barId}/week`;
        }
        const response = await server.get(url, {
            headers: { token: localStorage?.getItem('token') }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const getMonthStatistics = async (barId=null) => {
    try {
        let url = `/statistics/month`;
        if(barId && barId!=='all_bars'){
            url = `/statistics/bar/${barId}/month`;
        }
        const response = await server.get(url, {
            headers: { token: localStorage?.getItem('token') }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}
