import { Box, Card, Grid } from "@mui/material";
import React from "react";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import InfoIcon from '@mui/icons-material/Info';

const icons_map = {
    "cash" : <LocalAtmIcon style={{fontSize: '60', color: 'green'}} />,
    "card" : <CreditCardIcon style={{fontSize: '60', color: 'gray'}} />,
    "online" : <BookOnlineIcon style={{fontSize: '60', color: '#2065D1'}} />,
    "info" : <InfoIcon style={{fontSize: '60', color: 'blue'}} />
}

class HorizontalWidget extends React.Component {
    render() {
        return (
                <Card style={{padding: 10}}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Box
                                justifyContent="center"
                                alignItems="center"
                            >
                                {icons_map[this.props?.icon] || icons_map["info"]}
                                {(this.props?.iconText) ? (
                                    <p style={{fontWeight: 'bolder' ,paddingLeft:'10px'}}>{this.props?.iconText}</p>
                                ) : null}
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <p style={{fontSize: 30, fontWeight: 'bolder'}}>{this.props?.title}</p>
                            <p >{this.props?.text}</p>
                        </Grid>
                    </Grid>
                </Card>
        );
    }
}

export default HorizontalWidget;
