import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import styled from "styled-components";

const defaultProps = {
  containerType: "",
  closeDragItem: () => {},
  highlightDrop: () => {},
  onDrop: () => {},
  isOverEvent: () => {},
  path: "",
  dragItem: {},
  canDrag: false,
  isEmpty: false,
  padding: "",
  dragType: "",
  dropType: "",
};
const Block = styled.div`
  width: 100%;
`;
const DragNDropContainer = ({
  path,
  dragItem,
  onDrop,
  children,
  highlightDrop,
  canDrag,
  closeDragItem,
  containerType,
  isOverEvent,
  isEmpty,
  padding,
  style,
  dragType,
  dropType,
}) => {
  const [hoverPosition, setHoverPosition] = useState("top");
  let pathRef = useRef();
  pathRef.current = path;
  const [{ isDragging }, drag] = useDrag({
    type: dragType,
    item: () => ({ dragItem, path }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag,
  });
  useEffect(() => {
    if (isDragging) {
      closeDragItem(dragItem?.element?._id);
    }
  }, [isDragging]);
  const ref = useRef(null);
  const [{ draggedItem, isOverCurrent }, drop] = useDrop({
    accept: dropType,
    drop: (item: dragItem, monitor) => {
      if (monitor.isOver()) {
        // const destPath = path.split('.');
        // if (!isEmpty) {
        //   if (hoverPosition === 'bottom') {
        //     destPath.push(String(Number(destPath.pop()) + 1));
        //   }
        // }
        onDrop(draggedItem, path);
      }
    },
    collect(monitor) {
      return {
        draggedItem: monitor.getItem(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      };
    },

    hover(draggItem, monitor) {
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      /** Determine mouse position */
      const clientOffset = monitor.getClientOffset();
      /** Get pixels to the top */
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (hoverClientY < hoverMiddleY) {
        setHoverPosition("top");
      } else {
        setHoverPosition("bottom");
      }
      if(path !=='' && hoverPosition!==''){
        highlightDrop(path, hoverPosition);
      }
    },
  });

  useEffect(() => {
    if (isOverCurrent) {
      isOverEvent(isOverCurrent);
    } else {
      highlightDrop('', '');
      isOverEvent(isOverCurrent);
    }
  }, [isOverCurrent]);

  let containerBody;
  let opacity;
  if (containerType === "droppable") {
    containerBody = drop(ref);
    opacity = isDragging ? 0.5 : 1;
  } else if (containerType === "draggable") {
    containerBody = drag(ref);
    opacity = isDragging ? 0.8 : 1;
  } else {
    containerBody = drag(drop(ref));
    opacity = isDragging ? 0.5 : 1;
  }
  // drag(drop(ref));
  return (
    <Block ref={containerBody} style={{ opacity, ...style }}>
      {children}
    </Block>
  );
};
DragNDropContainer.defaultProps = defaultProps;
export default DragNDropContainer;
