// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import common_en from '../translations/en/common_en.json';
// import common_fr from '../translations/fr/common_fr.json'

// i18n
//   .use(initReactI18next)
//   .init({
//     resources: {
//       en: {
//           common: common_en               // 'common' is our custom namespace
//       },
//       fr: {
//           common: common_fr
//       },
//   },
//     fallbackLng: 'en',
//     interpolation: {
//       escapeValue: false,
//     },
//   });

// export default i18n;

import i18n from "../i18n";

export default i18n;