import axios from 'axios';
import config from '../config';

const server = axios.create({
    baseURL: config.baseUrl
});

export const uploadFile = async (payload) => {
    try {
        const response = await server.post(`/assets`,
            payload,
            {headers:{token:localStorage?.getItem('token')}}
        );
        return response.data;
    } catch (error) {
        return error;
    }
};