import { Navigate, useRoutes } from "react-router-dom";

// layouts
import AdminLayout from "./layouts/admin";

import UserPage from './pages/UsersPage';
import BarsPage from './pages/BarsPage';
import DashboardPage from './pages/DashboardPage';
import ClientsPage from './pages/ClientsPage';
import Login from './pages/Login';
import AddEditUser from "./pages/AddEditUser";
import ReportsPage from "./pages/Reports";
import {useNavigate } from "react-router-dom";
import AddEditBar from "./pages/AddEditBar/AddEditBar";

// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate();
  const routes = useRoutes([
    {
      path: "/",
      element: <AdminLayout navigate={navigate} />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: 'dashboard', element: <DashboardPage navigate={navigate} /> },
        { path: 'clients', element: <ClientsPage navigat={navigate} />},
        { path: "users", element: <UserPage navigate={navigate} /> },
        { path: "users/create", element: <AddEditUser navigate={navigate} /> },
        { path: "users/:user_id", element: <AddEditUser navigate={navigate} />, isExact: true },
        
        { path: 'bars', element: <BarsPage navigate={navigate} /> },
        { path: 'bars/create', element: <AddEditBar navigate={navigate} /> },
        { path: 'bars/:bar_id', element: <AddEditBar navigate={navigate} /> },
        { path: 'reports', element: <ReportsPage navigate={navigate} /> },
      ],
    },
    {
      path: "login",
      element: <Login navigate={navigate} />,
    },
    {
      path: "*",
      element: <Navigate to="/login" replace />,
    },
  ]);

  return routes;
}
