import axios from 'axios';

import config from '../config';

const server = axios.create({
    baseURL: config.baseUrl
});

const login = async (payload) => {
    try {
        const response = await server.post(`/login`, {
            ...payload
        });
        return response?.data;
    } catch (error) {
        return error.response.data;
    }
}

const register = async (data) => {
    try {

        let payload = {
            fullName: data.full_name,
            email: data.email,
            username: data.username,
            password: data.password
        }

        const response = await server.post(`/register`, payload);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const verifyToken = async () => {
    try {        
        const response = await server.post(`/verify_token`, {}, {
            headers: {
                token: await localStorage.getItem(config.tokenKey)
            }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

// const resetPassword = async (payload) => {
//     try {
//         const response = await server.post(`/reset_password`, payload, {
//             headers: {
//                 token: await localStorage.getItem(config.tokenKey)
//             }
//         });
//         return response.data;
//     } catch (error) {
//         return error.response.data;
//     }
// }


export default {
    login,
    register,
    verifyToken,
    // resetPassword
}
