import React from "react";
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Card, CardContent, CardHeader } from "@mui/material";
import config from "../../config";
import { fCurrency } from "../../utils/formatNumber";
import i18n from "../../i18n";

function CustomToolbar() {
    return (
      <GridToolbarContainer style={{justifyContent: 'space-between'}}>
        <GridToolbarQuickFilter style={{padding: 10}} />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
}

class OnlineTransactionsTable extends React.Component {

    constructor(props) {
        super(props);
        this.rows=this.props?.onlineTransactions;
        this.rows = this.rows.map((row, index) => {
            return {
                ...row,
                merchantTranId: row.twintDirect?.merchantTranId
            }
        });
        this.columns = [
            { field: 'id', headerName: i18n.t('order_id'), flex: 1},
            { field: 'merchantTranId', headerName: i18n.t('merchant_tran_id'), flex: 1},
            { field: 'payrexxId', headerName: i18n.t('payrexx_id'), flex: 1},
            { field: 'payrexxTransactionId', headerName: i18n.t('payrexx_transaction_id'), flex: 1},
            { field: 'payrexxReferenceId', headerName: i18n.t('payrexx_reference_id'), flex: 1},
            { field: 'payrexxUuid', headerName: i18n.t('payrexx_Uuid'), flex: 1},
            { field: 'payrexxStatus', headerName: i18n.t('payrexx_Status'), flex: 1},
            { field: 'paymentStatus', headerName: i18n.t('payment_Status'), flex: 1},
            // { field: 'orderId', headerName: 'Order ID', flex: 1},
            { field: 'created_at', headerName: i18n.t('date'), flex: 1},
            { field: 'total', headerName: i18n.t('total'), type: 'number', flex: 1,
                valueFormatter: (params) => {
                    return fCurrency(params.value, i18n.t('OPTIONS.i18n_number_format'), config.currencyCode, false);
                }
            },
        ];
          
        this.state = {
            pageSize: 10
        }
    }

    render() {


        return (
            <Card style={{borderRadius: 'unset'}}>
                <CardHeader title={i18n.t('online_transactions')} />
                <CardContent>
                    <DataGrid
                        autoHeight
                        rows={this.rows}
                        columns={this.columns}
                        pageSize={this.state.pageSize}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        onPageSizeChange={(newPageSize) => this.setState({pageSize: newPageSize})}
                        components={{ Toolbar: CustomToolbar }}
                        componentsProps={{
                            toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        disableSelectionOnClick
                    />
                </CardContent>
            </Card>
        );
    }
}

export default OnlineTransactionsTable;